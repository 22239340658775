import { useState, useRef, useMemo, useReducer, Reducer } from "react";

import Stepper from "components/molecules/Stepper";

import "../../Waitlists/NewCampaign/NewCampaign.css";
import "../../Waitlists/NewCampaign/UploadSpreadsheetStep/UploadSpreadsheetStep.css";
import CompletedSuspensionStep from "./CompletedSuspensionStep";
import ConfigureSuspensionStep from "./ConfigureSuspensionStep";
import "./NewSuspension.css";
import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "./NewSuspensionContext";
import {
  createNewSuspensionContextState,
  type NewSuspensionContextState,
} from "./NewSuspensionContextState";
import { newSuspensionReducer } from "./NewSuspensionReducer";
import type { NewSuspensionAction } from "./NewSuspensionReducer";
import VerifySuspensionStep from "./VerifySuspensionStep";

export type ProfessionalOption = {
  label: string;
  value: string;
  centerId: string | null;
};

const CONFIGURE_SUSPENSION_STEP = 1;
const VERIFY_STEP = 2;
const COMPLETED_STEP = 3;
const STEPS = ["Configurar suspensión", "Verificar pacientes"];

type NewSuspensionStep =
  | typeof CONFIGURE_SUSPENSION_STEP
  | typeof VERIFY_STEP
  | typeof COMPLETED_STEP;

const NewSuspension = () => {
  const [newSuspension, dispatch] = useReducer<
    Reducer<NewSuspensionContextState, NewSuspensionAction>
  >(newSuspensionReducer, createNewSuspensionContextState());
  const ref = useRef<HTMLDivElement>(null);
  const [appointmentsCount, setAppointmentsCount] = useState<number>();

  const setStep = (step: number) => {
    ref.current?.parentElement?.scrollTo({ top: 0 });
    if (enabledSteps.includes(step as NewSuspensionStep)) {
      setCurrentStep(step as NewSuspensionStep);
    }
  };
  const [currentStep, setCurrentStep] = useState<NewSuspensionStep>(
    CONFIGURE_SUSPENSION_STEP,
  );

  const enabledSteps = useMemo(() => {
    if (currentStep === CONFIGURE_SUSPENSION_STEP) {
      return [CONFIGURE_SUSPENSION_STEP];
    }

    return [CONFIGURE_SUSPENSION_STEP, VERIFY_STEP];
  }, [currentStep]);

  const onSuccess = (appointmentsCount: number) => {
    setAppointmentsCount(appointmentsCount);
    setCurrentStep(COMPLETED_STEP);
  };

  const resetForm = () => {
    setCurrentStep(CONFIGURE_SUSPENSION_STEP);
  };

  return (
    <NewSuspensionContext.Provider value={newSuspension}>
      <NewSuspensionDispatchContext.Provider value={dispatch}>
        <div className="NewSuspension">
          <div className="NewCampaign" ref={ref}>
            <h2 className="NewCampaign__title">Suspensión de citas</h2>
            <div className="NewCampaign__content">
              <Stepper
                currentStep={currentStep}
                enabledSteps={enabledSteps}
                onClick={setStep}
                steps={STEPS}
              />
              {currentStep === CONFIGURE_SUSPENSION_STEP && (
                <ConfigureSuspensionStep
                  onSubmit={() => setCurrentStep(VERIFY_STEP)}
                />
              )}
              {currentStep === VERIFY_STEP &&
                !!newSuspension.selectedProfessional && (
                  <VerifySuspensionStep onSuccess={onSuccess} />
                )}
              {currentStep === COMPLETED_STEP &&
                !!newSuspension.selectedProfessional &&
                appointmentsCount !== undefined && (
                  <CompletedSuspensionStep
                    resetForm={resetForm}
                    selectedProfesional={
                      newSuspension.selectedProfessional.label
                    }
                    appointmentsCount={appointmentsCount}
                  />
                )}
            </div>
          </div>
        </div>
      </NewSuspensionDispatchContext.Provider>
    </NewSuspensionContext.Provider>
  );
};

export default NewSuspension;
